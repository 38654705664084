import { useMemo } from 'react';
import { Link } from 'react-router-dom';
// Local imports
import classes from './index.module.scss';
import { Table } from './types';

function PriceListPage() {
	// CONSTANTS
	const priceTables = useMemo<Table[]>(
		() => [
			{
				title: 'Kezelések',
				rows: [
					['Arctisztítás (tini):', '9200.-'],
					['Arctisztítás (felnőtt) - letisztítás, radírozás, tisztítás, ampulla, pakolás:', '12 000.-'],
					['Arctisztítás + vio:', '12 500.-'],
					['Arctisztítás + iontós hatóanyag-bevitel:', '13 800.-'],
					['Arctisztítás + thermos hatóanyag-bevitel:', '13 000.-'],
					['Arctisztítás + hidrodermabrázió:', '13 000.-'],
					['Arctisztítás + mikrodermabrázió:', '14 600.-'],
					['Arctisztítás + mikrodermabrázió + mezoterápia:', '21 900.-'],
					['Arctisztítás + mikrodermabrázió + iontoforézis:', '16 800.-'],
					['Hátkezelés (területtől függően):', '9 900 -', '13 000.-'],
					['AHA gyümölcssavas hámlasztás:', '15 500.-'],
					['AHA gyümölcssavas hámlasztás (prémium):', '18 800.-'],
					['Oxygen therapy', '14 300.-'],
					['Collagen anti-ageing kezelés', '13 000.-'],
					['3 Peptid anti-ageing ránctalanító lifting kezelés', '16 500.-'],
					['Arctisztítás + szemöldökszedés:', '13 000.-'],
					['Arctisztítás + szempillafestés:', '13 800.-'],
					['Arctisztítás + szemöldökszedés + szempillafestés:', '14 500.-'],
					['Arctisztítás + szempilla-, szemöldök festés és szedés:', '15 400.-'],
				],
			},
			{
				title: 'Frissítő kezelések',
				rows: [
					['Arcmasszázs + radír:', '5 400.-'],
					['Dekoltázsmasszázs + radír:', '6 500.-'],
					['Arcmasszázs + radír + pakolás:', '7 600.-'],
					['Arcmasszázs + mikrodermabrázió + pakolás:', '9 800.-'],
					['Arcmasszázs + mikrodermabrázió + iontoforézis:', '14 300.-'],
					['Dekoltázsmasszázs + radír + pakolás:', '10 400.-'],
					['Dekoltázsmasszázs + mikrodermabrázió + pakolás:', '15 000.-'],
					['Arcmasszázs + mikrodermabrázió + arcmezo', '18 000.-'],
					['Radír (mechanikus):', '1 200.-'],
					['Radír (enzimes):', '1 700.-'],
					['Pakolások:', '2 200 -', '4 400.-'],
					['Vio:', '1000.-'],
					['Mikroforézis (hatóanyagtól függően):', '3 800 -', '5 000.-'],
					['Mikrodermabrázió arc + nyak + dekoltázs:', '4 300 -', '5 700.-'],
					['Hidrodermabrázió:', '3 900.-'],
				],
			},
			{
				title: 'Gyantázások',
				rows: [
					['Szemöldök:', '1 400.-'],
					['Bajusz:', '900.-'],
					['Áll:', '600.-'],
					['Arc:', '1 700.-'],
					['Hónalj:', '2 200.-'],
					['Kar:', '2 600.-'],
					['Hónalj + kar:', '4 300.-'],
					['Fazon:', '2 600.-'],
					['Fazon (teljes - elöl):', '4 100.-'],
					['Fazon (teljes - elöl + popsi):', '5 200.-'],
					['Láb (térdig):', '3 200.-'],
					['Láb (végig):', '5 400.-'],
					['Comb:', '3 400.-'],
					['Láb (végig) + fazon:', '7 600.-'],
					['Láb (végig) + teljes fazon:', '8 100.-'],
					['Hát:', '4 000.-'],
					['Mellkas:', '3 000.-'],
					['Has:', '2 000.-'],
					['Mellkas + has:', '4 500.-'],
				],
			},
			{
				title: 'Festések',
				rows: [
					['Szempillafestés:', '2 000.-'],
					['Szemöldökfestés:', '1 300.-'],
					['Szempilla,- szemöldökfestés és szedés:', '4 400.-'],
					['Szemöldökszedés (kézi):', '1 200.-'],
				],
			},
			{
				title: 'Sminkek',
				rows: [
					['Nappali:', '6 600.-'],
					['Alkalmi:', '8 000.-'],
					['Mennyasszonyi:', '15 500.-'],
					['Szempilladauer:', '6 800.-'],
				],
			},
			{
				title: 'Testmasszázs',
				rows: [
					['Karok (30 perc):', '5 000.-'],
					['Lábak (30 perc):', '6 000.-'],
					['Hát (40 perc):', '6 900.-'],
					['Hát + kar:', '8 800.-'],
					['Egész test (1,5 óra):', '9 900.-'],
				],
			},
		],
		[],
	);

	// RENDERES
	const priceList = useMemo<JSX.Element[]>(
		() =>
			priceTables.map((table, idx) => (
				<table key={`priceTable-${idx}`}>
					<thead>
						<tr>
							<th colSpan={2}>{table.title}</th>
						</tr>
					</thead>
					<tbody>
						{table.rows.map((row, idx) => (
							<tr key={`tableRow-${idx}`} className={classes.tableRow}>
								<td>{row?.[0]}</td>
								<td>
									<span>{row?.[1]}</span>
									{row?.[2] && <span>&nbsp;{row?.[2]}</span>}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)),
		[priceTables],
	);

	return (
		<div className={classes.priceListPage}>
			<main>
				<h1>Árlista (2024)</h1>
				{priceList}
			</main>
			<footer>
				<Link to="/">
					<button>Vissza a főoldalra</button>
				</Link>
			</footer>
		</div>
	);
}

export default PriceListPage;
